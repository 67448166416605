import React from "react";
import { Link } from "react-router-dom";
import { toSnakeCase } from "../../helper";
import parse from "html-react-parser";

const ProjectCard = ({ projects }) => {
  const truncateDescription = (desc) => {
    if (desc.length > 150) {
      return parse(desc.slice(0, 200) + "...");
    }
    return desc;
  };

  return (
    <div className="pb-5">
      {projects.map((project, index) => (
        <div
          key={index}
          className="border border-white border-solid rounded-xl w-3/4 md:w-3/4 bg-zinc-50 shadow-xl hover:border hover:border-primary my-10 lg:my-20 mx-auto"
        >
          <Link
            to={`/project-details/${project.slug}`}
            className="grid grid-cols-1 md:grid-cols-2 gap-10"
          >
            <div className="">  
              <img
                className="bg-blue-50 w-full h-full object-cover  rounded-tl-xl rounded-tr-xl lg:rounded-tr-none lg:rounded-l-xl"
                src={project.image || "./default-project-img.png"}
                alt={project.title}
              />
            </div>

            <div className="mt-5 md:pr-20 lg:pr-10 flex-col flex justify-around pt-5">
              <div>
                <h2 className="text-2xl font-semibold md:text-2xl lg:text-3xl">
                  {project.title}
                </h2>
                <div className="text-justify md:hidden lg:block pt-6 px-9 lg:px-0">
                  {truncateDescription(project.description)}
                </div>
              </div>

              <div className="flex justify-center pt-7 pb-8 lg:pt-0 p-1 lg:p-3 gap-4 lg:gap-4">
                {project.technologies.map((tech, i) => (
                  <figure key={i}>
                    <img
                      src={`icons/${toSnakeCase(tech.name)}.png`}
                      className="w-6 h-6 lg:w-8 lg:h-8"
                      alt={tech.name}
                    />
                  </figure>
                ))}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ProjectCard;
